import consumer from "./consumer"

consumer.subscriptions.create("UserScoresChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log('user scores connected');
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const target = document.getElementById('user_score_table');
    if (data.html) {
      target.innerHTML = data.html;
    }
    // Called when there's incoming data on the websocket for this channel
  }
});
